import React, { useCallback, useEffect, useState } from 'react'
import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css'
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import ImageDeleteComponent from './ImageDeleteComponent';


const ImageInfoComponent = (props) => {
  const navigate = useNavigate();
  const [thumbImageUri, setThumbImageUri] = useState("");
  const [mainImageUri, setMainImageUri] = useState("");

  useEffect(() => {
    setThumbImageUri(props.thumbImageUri);
    setMainImageUri(props.thumbImageUri.replace('/thumb/', '/'));
  }, []);

  function imageLinkCopy() {
    navigator.clipboard.writeText(process.env.REACT_APP_API_URL + "/" + mainImageUri);
    toast.info("کپی شد.");
  }

  function getImages() {
    props.getImages();
  }

  return (
    <div>
      <img className='w-100 bg-primary h-50' style={{maxHeight: '80vh'}} src={process.env.REACT_APP_API_URL + "/" + mainImageUri} />
      <hr />
      <div className='d-flex align-items-center'>
      <button className='btn btn-sm btn-info p-0' style={{ cursor: 'pointer' }} onClick={() => imageLinkCopy()}><span class="uil--copy"></span></button>

        <iconify-icon icon="solar:copy-linear" onClick={() => imageLinkCopy()} width="23" height="23" style={{ color: "black" }}></iconify-icon>
        <input value={process.env.REACT_APP_API_URL + "/" + mainImageUri} type="text" id="disabledTextInput" class="form-control ms-3" placeholder="Disabled input" dir='ltr' disabled />
        {mainImageUri != "" && <ImageDeleteComponent imageName = {mainImageUri} getImages={getImages}/>}
      </div>
    </div>
  )
}



export default ImageInfoComponent;
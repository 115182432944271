import Content from "../../../components/Layout/card/Content";
import Card from "../../../components/Layout/card/Card";
import Title from "../../../components/Layout/card/Title";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import academyTeachers from "../../../services/academyTeachers.service";
import { useDispatch } from "react-redux";
import { logout } from "../../../actions/auth";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import SelectStudentModal from "./SelectStudentModal";
import courseStudentsService from "../../../services/courseStudents.service";
import persian from "react-date-object/calendars/persian"
import persian_fa from "react-date-object/locales/persian_fa"
import { DateObject } from "react-multi-date-picker";


const CourseStudents = (props) => {
    const [students, setStudents] = useState([]);
    const [courseId, setCourseId] = useState(-1);
    const [isPageCompleteLoad, setIsPageCompleteLoad] = useState(false);

    useEffect(() => {
        setStudents(props.students);
        setCourseId(props.courseId);
        setIsPageCompleteLoad(true);
    }, []);

    function removeStudentFromCourse(studentId) {
        courseStudentsService.removeStudentFromCourse(courseId, studentId).then(
            (response) => {
                setStudents(previousStudents => previousStudents.filter((data) => data.id != studentId))
            },
            (error) => {

            }
        );
    }

    function getAllStudentsOfCourse() {
        courseStudentsService.allStudentsOfCourse(courseId).then(
            (response) => {
                setStudents(response.data);
            },
            (error) => {

            }
        );
    }

    function changeStudentInCourseToVerified(element, data) {
        console.log("element => " + element.target.style.Color);
        courseStudentsService.changeStudentInCourseToVerified(courseId, data).then(
            (response) => {
                // toast.success("تایید شد.");
                element.target.style.visibility = "hidden";
            },
            (error) => {

            }
        );
    }

    function sendSmsStudentLink(studentId) {
        // setIsSearch(true);
        courseStudentsService.sendSmsStudentLink(courseId, studentId).then(
          (response) => {
            toast.success(response.data.message);
          },
          (error) => {
    
          }
        );
        // setIsSearch(false);
      }

    return (
        <div className="w-100">
            {/* <Card> */}
            <Title>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <p>لیست هنرآموزان</p>
                    {isPageCompleteLoad && <SelectStudentModal courseId={courseId} getAllStudentsOfCourse={getAllStudentsOfCourse} />}
                </div>

            </Title>
            <Content>
                <div class="table-responsive">
                    <table class="table table-hover">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>نام</th>
                                <th>فامیل</th>
                                <th>شماره موبایل</th>
                                <th>تاریخ ثبت نام</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {students.map((student, i) => {
                                return <tr>
                                    <td>{i + 1}</td>
                                    <td><p>{student["name"]}</p></td>
                                    <td>{student["family"]}</td>
                                    <td>{student["mobile"]}</td>
                                    <td>{new DateObject(student["registerationDate"]).convert(persian, persian_fa).toString()}</td>
                                    <td className="text-end">
                                        {!student["isVerified"] && <button onClick={(e) => changeStudentInCourseToVerified(e, student["id"])} type="button" class="btn btn-success me-2">تایید</button>}
                                        {student["isVerified"] &&<input value="ارسال لینک" type='button' className='btn btn-sm btn-info me-2' onClick={() => sendSmsStudentLink(student["id"])}></input>}
                                        <button onClick={() => removeStudentFromCourse(student["id"])} type="button" class="btn btn-sm btn-danger">حذف</button>
                                    </td>
                                </tr>
                            })}

                            {Object.keys(students).length === 0 &&
                                <tr><td className="text-center" colSpan={5}>رکوردی یافت نشد</td></tr>
                            }

                        </tbody>
                    </table>
                </div>
            </Content>
            {/* </Card> */}

        </div>
    );
};

export default CourseStudents;




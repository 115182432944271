import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { login } from "./actions/auth";
import { ToastContainer } from "react-toastify";
import { toast } from 'react-toastify';
import { SET_MESSAGE } from "./actions/types";

const textInputStyle = {
    border: "1.5px solid #E0E0E0",
    borderRadius: "8px",
    height: "40px",
    // padding: "0 16px",
    width: "100%",
}

const btnLoginInputStyle = {
    height: "44px",
    width: "100%",
    display: "block",
    backgroundColor: "#09f",
    border: "none",
    borderRadius: "5px",
    color: "#fff",
    cursor: "pointer",
    marginTop: "20px"
}

const LoginCore = (props) => {
    let navigate = useNavigate();

    const form = useRef();

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [academyUniqueCode, setAcademyUniqueCode] = useState("");
    const [loading, setLoading] = useState(false);
    // const [selectedUserType, setSelectedUserType] = useState("Teacher");
    const { message } = useSelector(state => state.message);
    const { isLoggedIn } = useSelector(state => state.auth);

    useEffect(() => {
        setAcademyUniqueCode(props.academyUniqueCode);
    }, [])

    const dispatch = useDispatch();

    const onChangeUsername = (e) => {
        const username = e.target.value;
        setUsername(username);
    };

    const onChangePassword = (e) => {
        const password = e.target.value;
        setPassword(password);
    };

    const validateAll = () => {
        let valueReturn = true;
        if (username == "") {
            toast.error("نام کاربری الزامیست");
            valueReturn = false;
        }
        if (password == "") {
            toast.error("رمز عبور الزامیست");
            valueReturn = false;
        }
        return valueReturn;
    }

    const handleLogin = (e) => {
        e.preventDefault();

        if (!validateAll())
            return;

        setLoading(true);


        dispatch(login(username, password, academyUniqueCode))
            .then((response) => {
                setLoading(false);
                navigate("/");
                window.location.reload();
            })
            .catch(() => {
                setLoading(false);
            });


    };

    if (message != "") {
        // toast.error(message);
        dispatch({ type: SET_MESSAGE, payload: "", });
    }

    if (isLoggedIn) {
        return <Navigate to="/" />;
    }

    return (
        <form onSubmit={handleLogin} ref={form} style={{ color: "#9c9c9c" }}>
            <label htmlFor="username" >نام کاربری</label>
            <input type="text" style={textInputStyle} name="username" value={username} onChange={onChangeUsername} />

            <label htmlFor="password" style={{ marginTop: "20px", display: "block" }}>رمز عبور</label>
            <input type="password" style={textInputStyle} name="password" value={password} onChange={onChangePassword} />

            {/* <select onChange={(e) => setSelectedUserType(e.target.value)} class="form-select mt-3" aria-label="Default select example" id="course-status">
                <option value="Teacher">مربی</option>
                <option value="Manager">مدیر</option>
            </select> */}

            <button style={btnLoginInputStyle} disabled={loading} >
                {loading ? (
                    <div class="spinner-border text-primary" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                ) :
                    (<span>ورود</span>)
                }
            </button>
            <div className="text-center" style={{ marginTop: "30px" }}>
                <span>کاربر جدید هستید؟ </span>
                <span>
                    <Link to="/register">یک حساب بسازید</Link>
                </span>
            </div>
            <ToastContainer position="top-right" rtl />
        </form>

    );
};

export default LoginCore;

import Content from "../components/Layout/card/Content";
import Card from "../components/Layout/card/Card";
import Title from "../components/Layout/card/Title";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import academyStudentsService from "../services/academyStudents.service";
import { useEffect, useState } from "react";

import { DateObject } from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian"
import persian_fa from "react-date-object/locales/persian_fa"

const ManagerHomePage = (props) => {
    const [isLoading, setIsLoading] = useState(-1);
    const [latestStudents, setLatestStudents] = useState([]);

    useEffect(() => {
        getLatestRegisteringStudents(1);
    }, [])

    function getLatestRegisteringStudents(pageNumber) {
        setLatestStudents([]);
        setIsLoading(true);
        academyStudentsService.getLatestRegisteringStudents(pageNumber).then(
            (response) => {
                setLatestStudents(response.data);
            },
            (error) => {
                setIsLoading(false);
            }
        );
        setIsLoading(false);
    }

    return (
        <div className="col-md-6">
            <Card>
                <Title>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <p>13آخرین ثبت نام ها</p>
                    </div>
                </Title>
                <Content>
                    <div class="table-responsive">
                        <table class="table table-hover text-center">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>نام</th>
                                    <th>نام خانوادگی</th>
                                    <th>نام کاربری</th>
                                    <th>نام درس</th>
                                    <th>تاریخ ثبت نام</th>
                                </tr>
                            </thead>
                            <tbody>
                                {latestStudents.map((student, i) => {
                                    return <tr>
                                        <td>{i + 1}</td>
                                        <td>{student["name"]}</td>
                                        <td>{student["family"]}</td>
                                        <td>{student["mobile"]}</td>
                                        <td>{student["courseName"]}</td>
                                        <td>{new DateObject(student["dateTime"]).convert(persian, persian_fa).toString()}</td>
                                    </tr>
                                })}



                                {Object.keys(latestStudents).length === 0 && !isLoading &&
                                    <tr><td className="text-center" colSpan={7}>رکوردی یافت نشد</td></tr>
                                }

                                {isLoading &&
                                    <tr><td className="text-center" colSpan={7}>
                                        <div class="spinner-border text-primary" role="status">
                                            <span class="visually-hidden">Loading...</span>
                                        </div>
                                    </td></tr>
                                }


                            </tbody>
                        </table>
                        <nav>
                            <ul class="pagination pagination-md justify-content-center">
                                <li class="page-item" aria-current="page"><a class="page-link" href="javascript:;" onClick={()=>getLatestRegisteringStudents(1)}>1</a></li>
                                <li class="page-item"><a class="page-link" href="javascript:;" onClick={()=>getLatestRegisteringStudents(2)}>2</a></li>
                                <li class="page-item"><a class="page-link" href="javascript:;" onClick={()=>getLatestRegisteringStudents(3)}>3</a></li>
                            </ul>
                        </nav>
                    </div>
                </Content>

            </Card>
        </div>
    );
};

export default ManagerHomePage;




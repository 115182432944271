const Settings = {
    name: "سامانه آموزشی",
    login_url: "/login",
    footer_text: "حقوق محفوظ می باشد",
    // API_URL: "http://localhost:5266/api",
    menu_items: [
        {
            name: "امکانات اصلی مربی",
            role: ["Teacher"],
            links: [
                { name: "کلاس های من", to: "/my-courses", icon: "mdi--google-classroom", },
                { name: "درس های من", to: "/books", icon: "tabler--books", },
                { name: "گالری تصاویر", to: "/image-gallery", icon: "clarity--image-gallery-line", },
            ]
        },
        {
            name: "مدیریت آموزشگاه ",
            role: ["Manager"],
            links: [
                // { name: "آموزشگاه", to: "/academy", icon: "fa-solid fa-lg fa-building-columns", },
                { name: "کاربران", to: "/users", icon: "mdi--users", },
                // { name: "دانشجویان", to: "/students", icon: "mdi--google-classroom", },
                { name: "کلاس های آموزشگاه", to: "/academy-courses", icon: "mdi--google-classroom", },
                { name: "مدیریت سایت", to: "/academy-site", icon: "dashicons--admin-site-alt", },
                { name: "مدیریت اپلیکیشن", to: "/student-panel", icon: "material-symbols--team-dashboard-outline", },
            ]
        },
        {
            name: "مدیر اصلی",
            role: ["Admin"],
            links: [
                // { name: "آموزشگاه", to: "/academy", icon: "fa-solid fa-lg fa-building-columns", },
                { name: "لیست آموزشگاه ها", to: "/academies", icon: "la--university", },
            ]
        }
    ],

};

export default Settings;

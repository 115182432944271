import axios from "axios";
import authHeader from "./auth-header";
import Settings from "../Settings";
import api from "./API";


const getLessonChartOfCourse = (courseId, lessonId) => {
  return api.get('/Teacher/Chart/GetLessonChartOfCourse/' + courseId + "/" + lessonId);
};

export default {
  getLessonChartOfCourse,
};

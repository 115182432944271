import axios from "axios";
import authHeader from "./auth-header";
import Settings from "../Settings";
import api from "./API";

const getCourseById = (courseId) => {
  return api.get('/Manager/Course/GetCourseByIdWithTeachers/' + courseId);
};

const toggleEnableRegisterationLink = (courseId) => {
  return api.get('/Manager/Course/ToggleEnableRegisterationLink/' + courseId);
};

const toggleVerifyStudentAutomation = (courseId) => {
  return api.get('/Manager/Course/ToggleVerifyStudentAutomation/' + courseId);
};

const toggleSendCourseLinkAutomation = (courseId) => {
  return api.get('/Manager/Course/ToggleSendCourseLinkAutomation/' + courseId);
};

const getCourseByRegisterationCode = (courseRegisterationCode) => {
  return api.get('/Student/Course/GetDetailsOfCourseByRegisterationCode?code=' + courseRegisterationCode);
};

const allCourseOfAcademy = () => {
  return api.get('/Manager/Course/AllCoursesOfAcademy');
};

const allCoursesOfTeacher = () => {
  return api.get('/Teacher/Course/AllCoursesOfTeacher');
};

const updateBookOfCourse = (courseId, bookId) => {
  return api.get('/Teacher/Course/UpdateBookOfCourse/' + courseId + "/" + bookId);
};

const insertCourse = (courseName, teacherID, statusID, startDate, logoFileName, showPrice) => {
  var data = {
    "name": courseName,
    "teacherID": teacherID,
    "statusID": statusID,
    "startDate": startDate,
    "logoFileName": logoFileName,
    "showPrice": showPrice,
  };

  return api.post('/Manager/Course/InsertCourseInAcademy', data);
};

const updateCourse = (courseId, courseName, teacherID, statusID, logoFileName, startDate, showPrice) => {
  var data = {
    "id": courseId,
    "name": courseName,
    "teacherID": teacherID,
    "statusID": statusID,
    "logoFileName": logoFileName,
    "startDate": startDate,
    "showPrice": showPrice,
  };

  return api.post('/Manager/Course/UpdateCourse', data);
};

const deleteCourse = (courseId) => {
  return api.delete('/Manager/Course/Delete/' + courseId);
};

export default {
  deleteCourse,
  toggleEnableRegisterationLink,
  toggleVerifyStudentAutomation,
  toggleSendCourseLinkAutomation,
  updateBookOfCourse,
  getCourseByRegisterationCode,
  insertCourse,
  updateCourse,
  allCourseOfAcademy,
  getCourseById,
  allCoursesOfTeacher,
};

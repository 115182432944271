import Content from "../../components/Layout/card/Content";
import Card from "../../components/Layout/card/Card";
import Title from "../../components/Layout/card/Title";
import 'react-toastify/dist/ReactToastify.css';
import { useCallback, useEffect, useState } from "react";
import courseService from "../../services/course.service";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import LessonsListEnableComponent from "./CourseLessonsComponent";
import CourseSettingComponent from "./CourseSetting.component";
import { useLocation } from "react-router-dom";
import CourseStudentsComponent from "./CourseStudents-component";
import AllDontCheckAnswersOfCourseComponent from "./AllDontCheckAnswersOfCourseComponent";
import chartsService from "../../services/charts.service";
import { BarChart, Bar, Rectangle, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Label } from 'recharts';

const LessonChartOfCoureseComponent = (props) => {
    const { state } = useLocation();
    const [courseId, setCourseId] = useState(-1);
    const [lessonId, setLessonId] = useState(-1);

    const [chart, setChart] = useState([]);
    const [selectedChart, setSelectedChart] = useState(0);
    const [dataGroupByStudent, setDataGroupByStudent] = useState([]);
    const [azmoonNames, setAzmoonNames] = useState([]);

    const [dataGroupByAzmoon, setDataGroupByAzmoon] = useState([]);

    const [isLoading, setIsLoading] = useState(false);

    const data1 = [
        {
            name: 'Page A',
            sh: 4000,
            pv: 2400,
            amt: 2400,
        },
        {
            name: 'Page B',
            sh: 3000,
            pv: 1398,
            amt: 2210,
        },
        {
            name: 'Page C',
            sh: 2000,
            pv: 9800,
            amt: 2290,
        },
    ];

    useEffect(() => {
        setCourseId(props.courseId);
        setLessonId(props.lessonId);
        getLessonChartOfCourse(props.courseId, props.lessonId);
    }, []);

    function getLessonChartOfCourse(courseId, lessonId) {
        // setIsLoading(true);
        chartsService.getLessonChartOfCourse(courseId, lessonId).then(
            (response) => {
                console.log("----------")
                setChart(response.data);
                console.log(response.data)
                createDataGroupByStudentFromChartData(response.data);
                createDataGroupByAzmoonFromChartData(response.data);
            },
            (error) => {

            }
        );
        // setIsLoading(false);
    }

    function createDataGroupByAzmoonFromChartData(chart1) {

        // createDataGroupByStudentAzmoonNameAndColor(chart1);

        var mData = [];
        chart1.forEach(element => {

            var obj = {};
            obj["name"] = element.azmoonName;

            console.log(obj);
            mData.push(obj);
        });

        setDataGroupByAzmoon(mData);
    }

    function createDataGroupByStudentFromChartData(chart1) {

        createDataGroupByStudentAzmoonNameAndColor(chart1);

        var mData = [];
        try {
            chart1.forEach(element => {

                var obj = {};
                var azmoon = {};
                obj["name"] = element[0].studentName + " " + element[0].studentFamily;
                element.forEach(element1 => {
                    obj[element1.azmoonName] = element1.earnedPoint;
                    azmoon["name"] = element1.azmoonName;
                });


                console.log(obj);
                mData.push(obj);
            });
        } catch (error) {

        }

        setDataGroupByStudent(mData);
    }

    function createDataGroupByStudentAzmoonNameAndColor(localChart) {
        var mAzmoons = [];
        localChart.forEach(function callback(value, index) {
            if (index == 1) {
                value.forEach(function callback(value1, index1) {

                    var azmoon = {};
                    azmoon["name"] = value1.azmoonName;
                    if (index1 == 0)
                        azmoon["color"] = "#6AA121";
                    else if (index1 == 1)
                        azmoon["color"] = "#8884d8";
                    else if (index1 == 2)
                        azmoon["color"] = "#FFA500";
                    else if (index1 == 3)
                        azmoon["color"] = "#A52A2A";
                    else if (index1 == 4)
                        azmoon["color"] = "#FF00FF";
                    else if (index1 == 5)
                        azmoon["color"] = "#FFC0CB";
                    else if (index1 == 6)
                        azmoon["color"] = "#000080";
                    else if (index1 == 7)
                        azmoon["color"] = "#34A56F";
                    mAzmoons.push(azmoon);
                }
                );
                setAzmoonNames(mAzmoons);
            }
        });
    }

    function optionChangeHandler(e) {
        setSelectedChart(e.target.value);
    }

    return (
        <div className="w-100" dir="ltr" style={{ height: '78vh' }}>
            <select onChange={(e) => optionChangeHandler(e)} class="form-select w-25 d-block m-auto" dir="rtl" aria-label="Default select example">
                {chart.map((chrt, i) => {
                    return <option value={i}>{chrt[0]["azmoonName"]}</option>
                })}
            </select>
            <hr />

            {chart.length > 0 &&
                <div className="w-100 h-100" key={selectedChart}>
                    <ResponsiveContainer width="100%" height="100%">
                        <BarChart
                            width={500}
                            height={300}
                            data={chart[selectedChart]}
                            layout="vertical"
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}>

                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis type="number" name="fff" orientation="top" stroke="#285A64" domain={[ 0, chart[selectedChart][0]["azmoonPoint"] ]} >
                                <Label value={chart[selectedChart].azmoonName} position="bottom" />
                            </XAxis>
                            <YAxis type="category" dataKey="studentFamily" axisLine={false} dx={-5} tickLine={false} style={{ fill: "#285A64" }} />
                            <Tooltip />
                            <Legend />


                            <Bar dataKey="earnedPoint" fill="#285A64" activeBar={<Rectangle fill="pink" stroke="blue" />} >
                                <Label value="امتیاز" position="bottom" />
                            </Bar>


                        </BarChart>
                    </ResponsiveContainer>
                    <hr />
                    <hr />
                </div>
            }


            {/* 
            {chart.length != 0 &&
                <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                        width={500}
                        height={300}
                        data={dataGroupByStudent}
                        layout="vertical"
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis type="number" orientation="top" stroke="#285A64" />
                        <YAxis type="category" dataKey="name" axisLine={false} dx={-5} tickLine={false}
                            style={{ fill: "#285A64" }} />
                        <Tooltip />
                        <Legend />

                        {azmoonNames.map((azmoon, i) => {
                            return <Bar dataKey={azmoon.name} fill={azmoon.color} activeBar={<Rectangle fill="pink" stroke="blue" />} />
                        })}



                    </BarChart>
                </ResponsiveContainer>
            } */}

            {chart.length == 0 &&
                <p>hhh</p>
            }
        </div>
    );
};

export default LessonChartOfCoureseComponent;




import Content from "../../components/Layout/card/Content";
import Card from "../../components/Layout/card/Card";
import Title from "../../components/Layout/card/Title";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { useEffect, useState } from "react";
import Popup from "reactjs-popup";
import MediaGalleryComponent from "../../pages/teacher/MediaGalleryComponent";
import imageService from "../../services/image.service";

const ImageSelector = (props) => {
    const [logoGalleryIsOpen, setLogoGalleryIsOpen] = useState(false);
    const [logoFileName, setLogoFileName] = useState("");

    useEffect(() => {
        setLogoFileName(props.logoFileName);
        // alert(props.logoFileName);
    }, []);

    function logoNameSelected(logoFileName) {
        setLogoGalleryIsOpen(false);
        setLogoFileName(logoFileName);
        props.onSelect(logoFileName);
    }

    function onSelectImageHandler(files) {
        const file = files[0];
        const formData = new FormData();
        formData.append('file', file)
        uploadCourseLogo(formData);
    }

    function uploadCourseLogo(formData) {
        imageService.uploadImage(formData).then(
            (response) => {

            },
            (error) => {

            }
        );
    }

    return (
        <div className="w-100">
            <div className="row mt-5">
                <div class="col-md-3" />
                <div class="col-md-6 form-horizontal form-label-left">
                    <div style={{ textAlign: 'center' }} for="file">
                        <Popup trigger={<img src={process.env.REACT_APP_API_URL + "/" + logoFileName} style={{ width: '150px', height: '150px', border: '1px solid red', margin: 'auto', display: 'block', cursor: 'pointer' }} />} open={logoGalleryIsOpen} on='click' onOpen={() => setLogoGalleryIsOpen(!logoGalleryIsOpen)} modal>
                            <MediaGalleryComponent logoNameSelected={logoNameSelected} />
                        </Popup>
                        <input type="file" id="file" style={{ visibility: "hidden" }} name="filename" onChange={(e) => onSelectImageHandler(e.target.files)} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ImageSelector;



